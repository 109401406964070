import { ApplicationSvgUrlIcon, Button } from '@getvim/atomic-ui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { useCallback, useMemo } from 'react';
import { IconTrash } from '../../../../assets/icons';
import { CleanInput } from '../../../../components-v2/clean-input';
import { AppToOverride, AppWithOverride } from '../../types';
import './application-item.less';
import { Switch } from '../../../../components/switch';

const URL_REGEX = /^https?:\/\/[^\s#$.?].\S*$/;

type ApplicationItemProps = {
  overrides: Omit<AppToOverride, 'appId'>;
  application: AppWithOverride;
  toggleAppEnabled: (appId: string, enabled: boolean) => void;
  onAppChange: <K extends keyof AppToOverride>(
    appId: string,
    key: K,
    value: AppToOverride[K],
  ) => void;
  clearOverrides: (appId: string) => void;
};
const ApplicationItem: React.FC<ApplicationItemProps> = ({
  overrides,
  application,
  toggleAppEnabled,
  onAppChange,
  clearOverrides,
}) => {
  const { appId } = application;
  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onAppChange(appId, e.target.name as keyof AppToOverride, e.target.value);
    },
    [appId, onAppChange],
  );
  const toggleEnabled = useCallback(
    (checked: boolean) => {
      toggleAppEnabled(appId, checked);
    },
    [toggleAppEnabled, appId],
  );
  const clearItem = useCallback(() => {
    clearOverrides(appId);
  }, [appId, clearOverrides]);
  const invalidUrl = useMemo(() => {
    return !!application.url && !URL_REGEX.test(application.url);
  }, [application.url]);

  const invalidTokenEndpointUrl = useMemo(() => {
    return !!application.tokenEndpoint && !URL_REGEX.test(application.tokenEndpoint);
  }, [application.tokenEndpoint]);

  const invalidLaunchEndpointUrl = useMemo(() => {
    return !!application.launchEndpoint && !URL_REGEX.test(application.launchEndpoint);
  }, [application.launchEndpoint]);
  return (
    <Accordion
      className="application-item"
      sx={{
        marginBottom: '8px',
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
        '&:hover': {
          boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.15)',
        },
        '& .MuiAccordionSummary-root': {
          border: '1px solid #BDBDBD',
          borderRadius: '8px',
          '&.Mui-expanded': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        },
        '& .MuiAccordionSummary-content': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        '& .MuiAccordionDetails-root': {
          border: '1px solid #BDBDBD',
          borderTop: 'none',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          fontSize: '14px',
        },
      }}
    >
      <AccordionSummary expandIcon={undefined} className="item-summary">
        <div className="item-metadata">
          <ApplicationSvgUrlIcon
            className="application-icon"
            iconUrl={application.iconUrl}
            alt={application.name}
          />
          {application.name}
        </div>
        <div className="item-actions">
          <Switch
            checked={application.isEnabledForUser}
            label="Enable"
            id={`toggle-enabled-${appId}`}
            onCheckedChange={toggleEnabled}
            stopPropagation
          />
          <span className="link-like">Override app store url</span>
          <ExpandMoreIcon />
        </div>
      </AccordionSummary>
      <AccordionDetails className="item-details">
        <div>
          <b>Existing override</b>
        </div>
        <div className="overrides">
          <CleanInput
            description="Version (e.g. 1.0.0)"
            className="app-version"
            value={overrides.version ?? ''}
            onChange={onInputChange}
            name="version"
          />
          <CleanInput
            description="Launch endpoint"
            className="app-launch-endpoint"
            error="Please provide a valid URL"
            showError={invalidLaunchEndpointUrl}
            submitted={invalidLaunchEndpointUrl}
            value={overrides.launchEndpoint ?? ''}
            onChange={onInputChange}
            name="launchEndpoint"
          />
          <CleanInput
            description="Token endpoint"
            className="app-token-endpoint"
            error="Please provide a valid URL"
            showError={invalidTokenEndpointUrl}
            submitted={invalidTokenEndpointUrl}
            value={overrides.tokenEndpoint ?? ''}
            onChange={onInputChange}
            name="tokenEndpoint"
          />
          <CleanInput
            description="Base iframe (Deprecated)"
            className="app-url"
            error="Please provide a valid URL"
            showError={invalidUrl}
            submitted={invalidUrl}
            value={overrides.url ?? ''}
            onChange={onInputChange}
            name="url"
          />
          <Button buttonType="link" onClick={clearItem}>
            <IconTrash />
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ApplicationItem;
