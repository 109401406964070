import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Loader from '../../components-v2/loader';
import Search from '../../components-v2/search';
import UserHeaderInfo from '../../components-v2/user-header-info';
import TabsBar from '../../components-v2/tabs-bar';
import PersonalInfo from '../../components-v2/personal-info';
import { Appointments } from '../../components-v2/appointments';
import { ReferralsOrders } from '../../components-v2/referrals-orders-lists';
import Formatter from '../../utils/formatter';
import useApi from '../../hooks/useApi';
import WithApiData from '../../hoks/withApiData';
import { useUserData } from '../../hooks/useUserData';
import { ItemModal } from '../../components-v2/patient-modal/itemModal';
import { Patient } from '../../types';
import { useFeatureFlag, Team } from '@getvim/feature-flags-react';
import { editDeletePatientsForNonAdminFF } from '../../utils/featureFlagClient';
import dayjs from 'dayjs';

import './styles.less';
import ProblemsList from '../../components-v2/problems-list';
import ConfirmDeleteModal from '../../components-v2/confirm-delete-modal';
import { GlobalContext } from '../../contexts/global';
import { IconPen, IconTrash } from '../../assets/icons';

const defaultTabsValue = {
  personalInfo: false,
  referrals: false,
  appointments: false,
  problemList: false,
};

const NO_DATA = '-';

const PatientProfile = ({ patientId }: any) => {
  const [currentPatientId, setPatientId] = useState<any>(patientId);
  const [activeTab, setActiveTab] = useState<any>({ ...defaultTabsValue, personalInfo: true });
  const [profileData, setProfileData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [temporaryData, setTemporaryData] = useState(null);
  const [organizations, setOrganizations] = useState<{ id: number; alias: string; name: string }[]>(
    [],
  );
  const [avatars, setAvatars] = useState([]);

  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [patientToBeDeleted, setPatientToBeDeleted] = useState<Patient | null>(null);
  const { enableImageUploader } = useContext(GlobalContext);

  const [enableEditDeletePatientsForNonAdmin] = useFeatureFlag({
    flagName: editDeletePatientsForNonAdminFF,
    defaultValue: false,
    team: Team.Interfaces,
  });

  useEffect(() => {
    if (deleteConfirmed && patientToBeDeleted) {
      deletePatient(patientToBeDeleted.id);
      setPatientToBeDeleted(null);
      setDeleteConfirmed(false);
      history.push('/patients');
    }
  }, [deleteConfirmed]);

  if (currentPatientId !== patientId) {
    setPatientId(patientId);
  }

  const toggleUpdateModal = () => {
    setUpdateModalOpen(!updateModalOpen);
  };

  const api = useApi();
  const user = useUserData();
  const history = useHistory();

  useEffect(() => {
    getPatient();
    getOrganizations();
    if (enableImageUploader) {
      getPatientAvatars();
    }
  }, [currentPatientId]);

  const getOrganizations = async () => {
    if (user.isAdmin) {
      const { data } = await api.getOrganizations({});
      setOrganizations(data);
    } else {
      setOrganizations([user.organization]);
    }
  };

  const getPatientAvatars = async () => {
    const existingAvatars = await api.getPatientAvatars();
    setAvatars(existingAvatars);
  };

  const getPatient = async () => {
    setIsLoading(true);
    const patient = await api.getPatient({ id: patientId });
    setIsLoading(false);
    setProfileData(patient);
  };

  const changeTab = (tab: string | number) => {
    if (!activeTab[tab]) {
      setActiveTab({ ...defaultTabsValue, [tab]: true });
    }
  };

  const updateUser = async (id: string, data: any, options: any) => {
    await api.updatePatient(id, data, options);
    getPatient();
  };

  const deletePatient = async (id: string) => {
    await api.deletePatient(id);
  };

  return (
    <div className="patient-profile" id="patient-profile">
      {profileData ? (
        <div className="patient-header-wrapper">
          <div className="header-content-v2">
            <Search />
            <UserHeaderInfo name={user.firstName} />
          </div>
          {isLoading && <Loader />}
          <div
            className="patient-header-v2"
            data-id="patient-info"
            data-patient-info={JSON.stringify(profileData)}
          >
            <div className="patient-header-avatar-v2">
              <img src={profileData.profilePicture} alt=" " />
            </div>
            <div className="patient-header-text-side">
              <h1
                id="patient-name"
                data-patient-first-name={profileData.firstName}
                data-patient-last-name={profileData.lastName}
                className="patient-header-title"
              >
                {`${profileData.firstName} ${profileData.lastName}`}
                {(user.isAdmin || enableEditDeletePatientsForNonAdmin) && (
                  <>
                    <div className="patient-header-title__action-btn-wrapper">
                      <IconPen
                        onClick={() => {
                          setUpdateModalOpen(true);
                        }}
                      />
                    </div>
                    <div className="patient-header-title__action-btn-wrapper">
                      <IconTrash
                        onClick={(e: any) => {
                          e.preventDefault();
                          setPatientToBeDeleted(profileData);
                          setConfirmDeleteModalOpen(true);
                        }}
                      />
                    </div>
                  </>
                )}
              </h1>
              <div className="patient-header-description">
                DoB: <strong>{dayjs(profileData.dob).format('MMM DD, YYYY') || NO_DATA}</strong>,
                Gender: <strong>{profileData.gender || NO_DATA}</strong>, Zip code:{' '}
                <strong>{profileData.location.zip || NO_DATA}</strong>, EHR Insurance:{' '}
                <strong>{profileData.ehrInsurance || NO_DATA}</strong>, Member ID:{' '}
                <strong>{profileData.memberId || NO_DATA}</strong>,
                {user.isAdmin && (
                  <>
                    {' '}
                    External ID: <strong>{(profileData.externalId || NO_DATA) + ', '}</strong>
                  </>
                )}
                MRN: <strong>{profileData.mrn || NO_DATA}</strong>, Clinic ID:{' '}
                <strong>{profileData.pcp?.clinics?.[0]?.id || NO_DATA}</strong>
              </div>
            </div>
          </div>
          <div className="patient-info-container gray-bg">
            <TabsBar activeTab={activeTab} setActiveTab={changeTab} />
            {activeTab.personalInfo ? <PersonalInfo profileData={profileData} /> : null}
            {activeTab.appointments ? <Appointments profileData={profileData} /> : null}
            {activeTab.referrals ? <ReferralsOrders patient={profileData} /> : null}
            {activeTab.problemList ? <ProblemsList patient={profileData} /> : null}
          </div>
        </div>
      ) : null}
      {updateModalOpen && organizations && (
        <ItemModal
          newItemTitle=""
          organizations={organizations}
          isOpen={updateModalOpen}
          onSave={() => {}}
          onClose={(formData) => {
            setTemporaryData(formData);
            toggleUpdateModal();
          }}
          isAdmin={user.isAdmin}
          isNewItem={false}
          onUpdate={async (id, patient, options) => {
            await updateUser(id, patient, options);
            setUpdateModalOpen(false);
          }}
          selectedPatient={profileData}
          temporaryData={temporaryData}
          avatars={avatars}
        />
      )}
      {confirmDeleteModalOpen && patientToBeDeleted && (
        <ConfirmDeleteModal
          dataToBeDeleted={Formatter.getFullName(
            patientToBeDeleted.firstName,
            patientToBeDeleted.lastName,
          )}
          onConfirmed={() => {
            setDeleteConfirmed(true);
            setConfirmDeleteModalOpen(false);
          }}
          onClose={() => setConfirmDeleteModalOpen(false)}
          isOpen={confirmDeleteModalOpen}
        />
      )}
    </div>
  );
};

export default WithApiData(PatientProfile);
