import React from 'react';
import { ReactComponent as DemographicsIcon } from '../../assets/new-icons/patient-profile-icons/demographics.svg';
import { ReactComponent as ContactInfoIcon } from '../../assets/new-icons/patient-profile-icons/contact-info.svg';
import { ReactComponent as IdentifiersIcon } from '../../assets/new-icons/patient-profile-icons/identifiers.svg';
import { ReactComponent as InsuranceIcon } from '../../assets/new-icons/patient-profile-icons/insurance.svg';
import { ReactComponent as LocationIcon } from '../../assets/new-icons/patient-profile-icons/location.svg';
import { ReactComponent as PcpIcon } from '../../assets/new-icons/patient-profile-icons/pcp.svg';
import { EllipsisText } from '@getvim/atomic-ui';
import dayjs from 'dayjs';
import { compact } from 'lodash-es';

import './styles.less';

const NO_DATA = '-';

const PatientProfile = ({ profileData }: any) => {
  const {
    firstName,
    lastName,
    middleName,
    dob,
    gender,
    id,
    externalId,
    mrn,
    location,
    ehrInsurance,
    groupId,
    payerId,
    memberId,
    homePhoneNumber,
    phoneNumber,
    email,
    pcp,
  } = profileData;

  const demographicsData = {
    'First name': firstName,
    'Last name': lastName,
    'Middle name': middleName,
    DoB: dayjs(dob).format('MMM DD, YYYY'),
    Gender: gender,
  };

  const identifiersData = {
    'Patient ID': externalId,
    'Vim patient ID': id,
    MRN: mrn,
  };

  const { addressLine1, city, state, zip } = location ?? {};
  const fullAddress = compact([addressLine1, city, state, zip]).join(', ');
  const locationData = {
    Address: fullAddress,
    'Address 1': addressLine1,
    City: city,
    State: state,
    'Zip code': zip,
  };

  const insuranceData = {
    'EHR insurance': ehrInsurance,
    'Group ID': groupId,
    'Payer ID': payerId,
    'Member ID': memberId,
  };

  const contactInfoData = {
    'Home phone number': homePhoneNumber?.number,
    'Mobile phone number': phoneNumber?.number,
    Email: email,
  };

  const pcpData = {
    'Associated provider': pcp && `${pcp.firstName} ${pcp.lastName}`,
  };

  return (
    <div className="personal-info">
      <div className="patient-profile-card">
        <PatientDetailsBox
          icon={<DemographicsIcon />}
          title="Demographics"
          details={demographicsData}
        />
        <PatientDetailsBox
          icon={<IdentifiersIcon />}
          title="Identifiers"
          details={identifiersData}
        />
        <PatientDetailsBox icon={<LocationIcon />} title="Location" details={locationData} />
        <PatientDetailsBox icon={<InsuranceIcon />} title="Insurance" details={insuranceData} />
        <PatientDetailsBox
          icon={<ContactInfoIcon />}
          title="Contact info"
          details={contactInfoData}
        />
        <PatientDetailsBox icon={<PcpIcon />} title="PCP" details={pcpData} />
      </div>
    </div>
  );
};

export default PatientProfile;

const PatientDetailsBox = ({
  icon,
  title,
  details,
}: {
  icon: any;
  title: string;
  details: { [key: string]: any };
}) => {
  return (
    <div className="patient-details-box">
      <div className="patient-details-box__title">
        {icon}
        <span className="patient-details-box__title-text">{title}</span>
      </div>
      {Object.entries(details).map(([key, value]) => (
        <div key={key} className="patient-details-box__item">
          <span>{key}:</span>
          <span>&nbsp;</span>
          <span className="patient-details-box__bold-text">
            <EllipsisText
              tooltipContent={value || NO_DATA}
              text={value || NO_DATA}
              width="large"
              bgColor="darkBlue"
            />
          </span>
        </div>
      ))}
    </div>
  );
};
