import React, { useState, useEffect, createRef } from 'react';
import { Button, Table } from 'react-bootstrap';
import * as uuid from 'uuid';
import { Toast } from '@getvim/atomic-ui';
import moment from 'moment';
import { Box, TextareaAutosize } from '@mui/material';
import { ReactComponent as IconTrash } from '../../assets/icons/trash.svg';
import { ReactComponent as IconPlus } from '../../assets/icons/icon-plus.svg';
import { ReactComponent as IconMinus } from '../../assets/icons/icon-minus.svg';
import { ReactComponent as IconArrowRight } from '../../assets/icons/right-arrow.svg';
import {
  AppointmentType,
  TaxonomyType,
  AssessmentType,
  Patient,
  PatientProblem,
  PatientProblemStatus,
  Taxonomy,
  MetadataType,
} from '../../types';
import useApi from '../../hooks/useApi';
import Loader from '../loader';
import { CleanInput } from '../clean-input';
import { SelectTypeahead } from '../select-typeahead';
import Formatter from '../../utils/formatter';

import './styles.less';

const ASSESSMENT_NOTES_METADATA_KEY = 'assessment_notes';

const { ToastTypes, createToast } = Toast;

interface AssessmentICDProps {
  patient?: Patient;
  selectedAppointment: AppointmentType;
  type: TaxonomyType;
  onChange: (metadata: MetadataType[]) => void;
  loading: boolean;
  isApptLocked: boolean;
}

export const AssessmentICD = ({
  patient,
  selectedAppointment,
  type,
  onChange,
  loading,
  isApptLocked,
}: AssessmentICDProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const [selected, setSelected] = useState<Taxonomy[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [generalNotes, setGeneralNotes] = useState<string>('');

  const [icdCodes, setIcdCodes] = useState<Taxonomy[]>([]);
  const [assessments, setAssessments] = useState<AssessmentType[]>([]);

  const [activePatientProblemList, setActivePatientProblemList] = useState<PatientProblem[]>([]);
  const [pastPatientProblemList, setPastPatientProblemList] = useState<PatientProblem[]>([]);

  const api = useApi();

  const notesInputElement = createRef<HTMLInputElement>();

  const assessmentsToDisplay = assessments.map((assessment) => {
    return {
      ...assessment,
      isExistsInActiveProblemList: !!activePatientProblemList.find((problem) =>
        problem.notes.find((note) => note.id === assessment.note?.id),
      ),
    };
  });

  const activeProblemListToDisplay = activePatientProblemList.map((problem) => {
    return {
      ...problem,
      isAvailableToAddToAppt: !assessments.find(
        (assessment) => assessment.taxonomy.id === problem.taxonomy.id,
      ),
    };
  });

  useEffect(() => {
    getPatientProblemList();
  }, []);

  const getPatientProblemList = async () => {
    if (patient) {
      try {
        setLoading(true);
        const data = await api.getPatientProblems(patient?.id, {});
        const resolvedProblemList: PatientProblem[] = [];
        const activeProblemsList = data.reduce(
          (previousValue: PatientProblem[], currentValue: PatientProblem) => {
            if (currentValue.status !== PatientProblemStatus.Resolved) {
              return [...previousValue, currentValue];
            }
            resolvedProblemList.push(currentValue);

            return previousValue;
          },
          [],
        );
        setActivePatientProblemList([...activeProblemsList]);
        setPastPatientProblemList([...resolvedProblemList]);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getAssessments();
  }, [selectedAppointment]);

  useEffect(() => {
    getTaxonomiesList();
  }, [assessments]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const parseGeneralNotesMetadata = (metadata: MetadataType[]) => {
    return metadata?.find((item) => item.key === ASSESSMENT_NOTES_METADATA_KEY)?.value ?? '';
  };

  const getAssessments = async () => {
    if (selectedAppointment) {
      try {
        setLoading(true);
        const appointment = await api.getAppointment({ id: selectedAppointment.id });

        const filteredAssessments = appointment.assessment.reduce(
          (filtered: AssessmentType[], option: AssessmentType) => {
            if (option.taxonomy.type === type) {
              filtered.push(option);
            }
            return filtered;
          },
          [],
        );

        const generalNotes = parseGeneralNotesMetadata(appointment.metadata);
        setGeneralNotes(generalNotes);
        setAssessments(filteredAssessments);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }
  };

  const getTaxonomiesList = async () => {
    try {
      setLoading(true);
      const { data } = await api.getTaxonomiesList({ type });
      const filteredData = data.filter(
        (taxonomy: { id: number }) =>
          !assessments.find((assessment) => assessment.taxonomy.id === taxonomy.id),
      );

      setIcdCodes(filteredData);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const deleteIcdCode = async (item: AssessmentType) => {
    setLoading(true);
    await api.removeAssessmentFromAppointment({
      noteId: item.note.id,
      appointmentId: selectedAppointment.id,
    });
    icdCodes.push(item.taxonomy);
    setIcdCodes([...icdCodes]);
    await getAssessments();
    await getPatientProblemList();
    setLoading(false);
  };

  const addICDCodes = async ({ taxonomy }: { taxonomy: Taxonomy }) => {
    setLoading(true);
    try {
      const itemToAdd = { taxonomy, note: { notes, id: -1 } };
      const { noteId } = await api.addAssessmentToAppointment({
        appointmentId: selectedAppointment.id,
        taxonomyId: itemToAdd.taxonomy.id,
        notes: itemToAdd.note.notes?.trim(),
      });
      itemToAdd.note.id = noteId;
      assessments.push(itemToAdd);
      const icdCodesList = icdCodes.filter((item) => item.id !== taxonomy.id);
      setIcdCodes([...icdCodesList]);
      setAssessments(assessments);
      setSelected([]);
      setNotes('');
    } catch (err: any) {
      createToast({
        title: `Oops, error!`,
        message: err.error?.message[0],
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  const addActiveProblemToAssessments = async (item: PatientProblem) => {
    setSelected([icdCodes.find((code) => code.id === item.taxonomy.id)!]);
    notesInputElement.current?.focus();
  };

  const addICDCodeToActivePatientProblemList = async (assessment: AssessmentType, event) => {
    const changedCheckTo = event.target.checked;

    if (patient) {
      setLoading(true);
      if (changedCheckTo) {
        await api.createPatientProblem(patient.id, {
          ...(assessment.note?.id
            ? {
                noteId: assessment.note?.id,
              }
            : {
                taxonomyId: assessment.taxonomy.id,
                notes: assessment.note?.notes,
              }),
        });
      } else {
        const { id: problemId } =
          activePatientProblemList.find((item) => item.taxonomy.id === assessment.taxonomy.id) ||
          {};
        if (problemId) {
          await api.deletePatientProblem({
            patientId: patient.id,
            id: problemId,
            noteId: assessment.note.id,
          });
        }
      }
      getPatientProblemList();
    }
  };

  const triggerCollapsedRow = (item, stateFunc) => {
    const openStatus = !item.isOpen;
    stateFunc((prevState: any) => {
      const foundIndex = prevState.findIndex((x) => x.id === item.id);
      // eslint-disable-next-line no-param-reassign
      prevState[foundIndex].isOpen = openStatus;
      return [...prevState];
    });
  };

  const handleGeneralNotesChange = (event) => {
    const value = event.target?.value;
    setGeneralNotes(value);
    onChange([{ key: ASSESSMENT_NOTES_METADATA_KEY, value }]);
  };

  return (
    <div className="assessment">
      {isLoading && <Loader />}
      <div className="block-left">
        <div className="assessment__select-wrapper">
          <SelectTypeahead
            description="* ICD"
            className="select-container"
            labelKey="label"
            filterBy={['label', 'description']}
            clearButton
            options={icdCodes}
            selected={selected}
            onChange={(value) => {
              setSelected(value);
            }}
            disabled={isApptLocked}
            // @ts-ignore
            renderMenuItemChildren={(label, option: Taxonomy) => (
              <div data-code={option.label}>
                {option.label}
                <div className="small-description" data-code={option.description}>
                  <small>{Formatter.toSentenceCase(option.description)}</small>
                </div>
              </div>
            )}
          />

          <CleanInput
            disabled={isApptLocked}
            ref={notesInputElement}
            className="clean-input-container"
            labelKey="notes"
            value={notes}
            description="Notes"
            onChange={(e: { target: { value: any } }) => {
              setNotes(e.target.value?.replace(/^\s*/, ''));
            }}
          />

          <Button
            disabled={!selected.length || isApptLocked}
            id="add-icd-btn"
            className="assessment__add-icd-btn"
            onClick={() => {
              addICDCodes({ taxonomy: selected[0] });
            }}
          >
            <i className="icon-plus-fat i-va-fix-2" />
            &nbsp;
            <span>Add</span>
          </Button>
        </div>

        <div>
          <Box
            className="text-area-wrapper no-padding"
            display="flex"
            flexDirection="column"
            flexGrow={1}
          >
            <div className="title">
              <span>General notes</span>
            </div>
            <Box>
              <TextareaAutosize
                data-test-id="assessment-general-notes"
                style={{ width: '100%', resize: 'none' }}
                maxRows={2}
                minRows={2}
                value={generalNotes}
                disabled={isApptLocked}
                onChange={handleGeneralNotesChange}
              />
            </Box>
          </Box>
        </div>

        <div className="table-wrapper">
          <Table borderless hover>
            <thead>
              <tr>
                <th className="label-column">ICD</th>
                <th className="description-column">Description</th>
                <th>Notes</th>
                <th className="add-to-pl-column">Add to PL</th>
                <th className="drop-icon-column" />
              </tr>
            </thead>
            <tbody>
              {assessmentsToDisplay.map((item: any) => {
                return (
                  <>
                    <tr key={item.taxonomy?.id} className="main-row">
                      <td className="label-column">{item.taxonomy?.label}</td>
                      <td
                        className="description-column"
                        title={Formatter.toSentenceCase(item.taxonomy?.description)}
                      >
                        {Formatter.toSentenceCase(item.taxonomy?.description)}
                      </td>
                      <td className="" title={item.note?.notes}>
                        {item.note?.notes}
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          disabled={isApptLocked}
                          className="checkbox-input"
                          checked={item.isExistsInActiveProblemList}
                          onChange={(value: any) =>
                            addICDCodeToActivePatientProblemList(item, value)
                          }
                        />
                      </td>
                      <td>
                        <IconTrash
                          className={`trash-icon${isApptLocked ? ' disabled' : ''}`}
                          onClick={() => !isApptLocked && deleteIcdCode(item)}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>

      <div className="vertical-line" />

      <div className="block-right">
        <span className="table-title">Active problem list</span>

        <div className="table-wrapper">
          <Table borderless>
            <thead>
              <tr>
                <th className="label-column">ICD</th>
                <th className="description-column">Description</th>
                <th className="date-column"> </th>
                <th className=""> </th>
              </tr>
            </thead>
            <tbody>
              {activeProblemListToDisplay.map((item: any) => {
                return (
                  <>
                    <tr key={item.id} className="main-row">
                      <td>
                        {item.isOpen ? (
                          <IconMinus
                            className="collapse-row-icon-minus"
                            onClick={() => triggerCollapsedRow(item, setActivePatientProblemList)}
                          />
                        ) : (
                          <IconPlus
                            className="collapse-row-icon-plus"
                            onClick={() => triggerCollapsedRow(item, setActivePatientProblemList)}
                          />
                        )}
                        {item.taxonomy?.label}
                      </td>
                      <td title={Formatter.toSentenceCase(item.taxonomy?.description)}>
                        {Formatter.toSentenceCase(item.taxonomy?.description)}
                      </td>
                      <td />
                      <td>
                        {item.isAvailableToAddToAppt ? (
                          <IconArrowRight
                            className={`icon-arrow-right${isApptLocked ? ' disabled' : ''}`}
                            onClick={() => addActiveProblemToAssessments(item)}
                          />
                        ) : null}
                      </td>
                    </tr>

                    {item.isOpen
                      ? item.notes.map((note: any) => {
                          const providerName = `${note.provider.firstName} ${note.provider.lastName}`;
                          return (
                            <>
                              <tr key={note.id}>
                                <td className="bordered" title={providerName}>
                                  {providerName}
                                </td>
                                <td className="bordered" title={note.notes}>
                                  {note.notes}
                                </td>
                                <td
                                  className="bordered"
                                  colSpan={2}
                                  title={moment(note.createdAt).format('DD/MM/YYYY')}
                                >
                                  {moment(note.createdAt).format('DD/MM/YYYY')}
                                </td>
                              </tr>
                            </>
                          );
                        })
                      : null}
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>

        <span className="table-title">Past medical history</span>

        <div className="table-wrapper">
          <Table borderless hover>
            <thead>
              <tr>
                <th className="label-column">ICD</th>
                <th className="description-column">Description</th>
                <th className="date-column"> </th>
              </tr>
            </thead>
            <tbody>
              {pastPatientProblemList.map((item: any) => {
                return (
                  <>
                    <tr key={item.id} className="main-row">
                      <td>
                        {item.isOpen ? (
                          <IconMinus
                            className="collapse-row-icon-minus"
                            onClick={() => triggerCollapsedRow(item, setPastPatientProblemList)}
                          />
                        ) : (
                          <IconPlus
                            className="collapse-row-icon-plus"
                            onClick={() => triggerCollapsedRow(item, setPastPatientProblemList)}
                          />
                        )}
                        {item.taxonomy?.label}
                      </td>
                      <td title={item.taxonomy?.description}>{item.taxonomy?.description}</td>
                      <td> </td>
                    </tr>

                    {item.isOpen
                      ? item.notes.map((note: any) => {
                          const providerName = `${note.provider.firstName} ${note.provider.lastName}`;
                          return (
                            <>
                              <tr key={note.id}>
                                <td className="bordered" title={providerName}>
                                  {providerName}
                                </td>
                                <td className="bordered" title={note.notes}>
                                  {note.notes}
                                </td>
                                <td
                                  className="bordered"
                                  title={moment(note.createdAt).format('DD/MM/YYYY')}
                                >
                                  {moment(note.createdAt).format('DD/MM/YYYY')}
                                </td>
                              </tr>
                            </>
                          );
                        })
                      : null}
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
