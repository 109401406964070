import { StoredAppOverrides } from './types';

const VIM_CONNECT_SDK_STORAGE_KEY = 'vim_connect_sdk_storage';

type localStorageKeys = 'appOverrides' | 'sdkUserInfo' | 'vimTokens' | 'enableLogs';

type StoredData = {
  appOverrides?: StoredAppOverrides;
  sdkUserInfo?: { email: string };
  vimTokens?: { [key: string]: { accessToken: string } };
  enableLogs?: boolean;
};
export const getSDKLocalStorage = (): StoredData => {
  try {
    return JSON.parse(localStorage.getItem(VIM_CONNECT_SDK_STORAGE_KEY) ?? '{}');
  } catch {
    return {};
  }
};

export const updateSDKLocalStorage = <T extends localStorageKeys>(key: T, value: StoredData[T]) => {
  const fromLocalStorage = getSDKLocalStorage();
  fromLocalStorage[key] = value;
  localStorage.setItem(VIM_CONNECT_SDK_STORAGE_KEY, JSON.stringify(fromLocalStorage));
};

export const mergeSDKLocalStorageKey = (key: string, value: any) => {
  const fromLocalStorage = getSDKLocalStorage();
  fromLocalStorage[key] = {
    ...(fromLocalStorage[key] ?? {}),
    ...value,
  };
  localStorage.setItem(VIM_CONNECT_SDK_STORAGE_KEY, JSON.stringify(fromLocalStorage));
};
