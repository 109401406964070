import { Button, Tooltip } from '@getvim/atomic-ui';
import React, { useCallback } from 'react';
import { IconPen, IconTrash } from '../../../assets/icons';
import { StoredAppOverrides } from '../types';

type Props = {
  existingOverrides: StoredAppOverrides;
  setAppToEdit: (appId: string) => void;
  deleteApp: (appId: string) => void;
};

const TooltipWrapped: React.FC<{ className: string; title: string }> = ({ className, title }) => (
  <Tooltip
    referenceClassName={className}
    conditionalTooltip
    contentMaxLength={20}
    tooltipContent={title}
    portal={false}
    hideTooltip={!title}
    bgColor="dark"
    placement="top"
  >
    <span className={className}>{title}</span>
  </Tooltip>
);

const Application: React.FC<{
  appId: string;
  version?: string;
  url?: string;
  launchEndpoint?: string;
  tokenEndpoint?: string;
  setAppToEdit;
  deleteApp;
}> = ({
  appId,
  version = '',
  url = '',
  setAppToEdit,
  deleteApp,
  launchEndpoint = '',
  tokenEndpoint = '',
}) => {
  const onEditClick = useCallback(() => {
    setAppToEdit(appId);
  }, [setAppToEdit, appId]);
  const onDeleteClick = useCallback(() => {
    deleteApp(appId);
  }, [deleteApp, appId]);
  return (
    <div className="app-override divider">
      <TooltipWrapped className="app-id" title={appId} />
      <TooltipWrapped className="app-version" title={version} />
      <TooltipWrapped className="app-url" title={launchEndpoint} />
      <TooltipWrapped className="app-url" title={tokenEndpoint} />
      <TooltipWrapped className="app-url" title={url} />
      <div className="app-actions">
        <Button buttonType="link" onClick={onEditClick}>
          <IconPen />
        </Button>
        <Button buttonType="link" onClick={onDeleteClick}>
          <IconTrash />
        </Button>
      </div>
    </div>
  );
};

const ApplicationOverridesList: React.FC<Props> = ({
  existingOverrides,
  setAppToEdit,
  deleteApp,
}) => {
  return (
    <>
      <span className="section-subtitle">Existing overrides</span>
      <div className="app-overrides">
        <div className="app-override header-row divider">
          <div className="app-id">App ID</div>
          <div className="app-version">V.</div>
          <div className="app-url">Launch endpoint url</div>
          <div className="app-url">Token endpoint url</div>
          <div className="app-url">
            Base iframe URL <strong>(Deprecated)</strong>
          </div>
          <div className="app-actions" />
        </div>
        {Object.entries(existingOverrides)
          .reverse()
          .map(([appId, app]) => (
            <Application
              key={appId}
              appId={appId}
              {...app}
              setAppToEdit={setAppToEdit}
              deleteApp={deleteApp}
            />
          ))}
      </div>
    </>
  );
};

export default ApplicationOverridesList;
