import React, { useCallback } from 'react';
import { AppToOverride, AppWithOverride } from '../types';
import ApplicationItem from './application-item/ApplicationItem';
import { sortBy } from 'lodash-es';

type ApplicationManagementProps = {
  applications: Record<string, AppWithOverride>;
  setApplications: React.Dispatch<React.SetStateAction<Record<string, AppWithOverride>>>;
};

const ApplicationManagement: React.FC<ApplicationManagementProps> = ({
  applications,
  setApplications,
}) => {
  const onAppChange = useCallback(
    <K extends keyof AppToOverride>(appId: string, key: K, value: AppToOverride[K]) => {
      setApplications((prev) => ({ ...prev, [appId]: { ...prev[appId], [key]: value } }));
    },
    [setApplications],
  );
  const toggleAppEnabled = useCallback(
    (appId: string, enabled: boolean) => {
      setApplications((prev) => ({
        ...prev,
        [appId]: { ...prev[appId], isEnabledForUser: enabled },
      }));
    },
    [setApplications],
  );
  const clearOverrides = useCallback(
    (appId: string) => {
      setApplications((prev) => ({
        ...prev,
        [appId]: {
          ...prev[appId],
          version: undefined,
          url: undefined,
          launchEndpoint: undefined,
          tokenEndpoint: undefined,
        },
      }));
    },
    [setApplications],
  );

  return (
    <div className="content-section">
      <div className="section-title">
        <div>Application management</div>
        <a
          href="https://docs.getvim.com/platform/testing.html#testing-your-application-with-vim-sandbox-ehr"
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
      </div>
      <div className="section-content">
        {sortBy(Object.values(applications), 'name').map((app) => (
          <ApplicationItem
            key={app.appId}
            application={app}
            toggleAppEnabled={toggleAppEnabled}
            overrides={applications[app.appId] ?? {}}
            onAppChange={onAppChange}
            clearOverrides={clearOverrides}
          />
        ))}
      </div>
    </div>
  );
};

export default ApplicationManagement;
