import { Button } from '@getvim/atomic-ui';
import React, { useCallback, useState } from 'react';
import { getSDKLocalStorage, updateSDKLocalStorage } from '../sdkLocalStorage';
import { AppToOverride, StoredAppOverrides } from '../types';
import ApplicationOverridesList from './ApplicationOverridesList';
import EditApplicationOverride from './EditApplicationOverride';

const emptyApp: AppToOverride = {
  appId: '',
  version: '',
  url: '',
  tokenEndpoint: '',
  launchEndpoint: '',
};

const localStorageKey = 'appOverrides';

const ApplicationManagement: React.FC = () => {
  const [apps, setApps] = useState<StoredAppOverrides>(() => {
    return getSDKLocalStorage()[localStorageKey] ?? {};
  });
  const [appToOverride, setAppToOverride] = useState<AppToOverride>(emptyApp);
  const onAppFieldChange = useCallback((key: keyof AppToOverride, value: string) => {
    setAppToOverride((prev) => ({ ...prev, [key]: value }));
  }, []);
  const setAppToEdit = useCallback(
    (appId: string) => {
      setAppToOverride({ appId, ...apps[appId] });
    },
    [apps],
  );
  const addApp = useCallback(() => {
    setApps((prev) => ({ ...prev, [appToOverride.appId]: appToOverride }));
    setAppToOverride(emptyApp);
  }, [appToOverride]);
  const deleteApp = useCallback((appId: string) => {
    setApps((prev) => {
      const newApps = { ...prev };
      delete newApps[appId];
      return newApps;
    });
  }, []);
  const cancel = useCallback(() => {
    setAppToOverride(emptyApp);
    setApps(getSDKLocalStorage()[localStorageKey] ?? {});
  }, []);
  const save = useCallback(() => {
    updateSDKLocalStorage(localStorageKey, apps);
    window.location.reload();
  }, [apps]);
  const isNewApp = !apps[appToOverride.appId];

  return (
    <div>
      <div className="section-title">
        <div>Override app store url &nbsp;</div>
        <a
          href="https://docs.getvim.com/platform/testing.html#testing-your-application-with-vim-sandbox-ehr"
          target="_blank"
          rel="noreferrer"
          className="section-title-url"
        >
          Learn more
        </a>
      </div>
      <span className="section-text">
        This allows you to choose the version or source url you want to use for testing your app
      </span>
      <div className="content-section">
        <EditApplicationOverride
          addApp={addApp}
          appToOverride={appToOverride}
          updateApp={onAppFieldChange}
          isNewApp={isNewApp}
        />
        <div className="divider" style={{ margin: '16px 0' }} />
        <div className="application-overrides-list-container">
          <ApplicationOverridesList
            existingOverrides={apps}
            setAppToEdit={setAppToEdit}
            deleteApp={deleteApp}
          />
        </div>
      </div>
      <div className="commit-buttons align-right">
        <Button buttonType="small" bgColor="white" onClick={cancel}>
          Cancel
        </Button>
        <Button buttonType="small" onClick={save}>
          Save and Reload Page
        </Button>
      </div>
    </div>
  );
};

export default ApplicationManagement;
