import { Button } from '@getvim/atomic-ui';
import { TextField, TextFieldProps } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { AppToOverride } from '../types';

const URL_REGEX = /^https?:\/\/[^\s#$.?].\S*$/;

const StyledTextField: React.FC<TextFieldProps> = ({
  onChange,
  value,
  label,
  name,
  ...textFieldProps
}) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      onChange={onChange}
      name={name}
      value={value}
      {...textFieldProps}
    />
  );
};

type Props = {
  appToOverride: AppToOverride;
  updateApp: (key: keyof AppToOverride, value: string) => void;
  addApp: (app: AppToOverride) => void;
  isNewApp: boolean;
};

const EditApplicationOverride: React.FC<Props> = ({
  addApp,
  appToOverride,
  updateApp,
  isNewApp,
}) => {
  const onAppFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateApp(e.target.name as keyof AppToOverride, e.target.value);
    },
    [updateApp],
  );
  const invalidUrl = useMemo(() => {
    return !!appToOverride.url && !URL_REGEX.test(appToOverride.url);
  }, [appToOverride.url]);

  const invalidTokenEndpointUrl = useMemo(() => {
    return !!appToOverride.tokenEndpoint && !URL_REGEX.test(appToOverride.tokenEndpoint);
  }, [appToOverride.tokenEndpoint]);

  const invalidLaunchEndpointUrl = useMemo(() => {
    return !!appToOverride.launchEndpoint && !URL_REGEX.test(appToOverride.launchEndpoint);
  }, [appToOverride.launchEndpoint]);

  return (
    <>
      <StyledTextField
        label="App id"
        required
        onChange={onAppFieldChange}
        name="appId"
        value={appToOverride.appId}
      />
      <StyledTextField
        label="App version"
        onChange={onAppFieldChange}
        name="version"
        value={appToOverride.version}
      />
      <StyledTextField
        label="Launch endpoint url"
        onChange={onAppFieldChange}
        name="launchEndpoint"
        error={invalidLaunchEndpointUrl}
        value={appToOverride.launchEndpoint}
        helperText={
          invalidLaunchEndpointUrl ? 'Please provide a valid launch endpoint URL' : undefined
        }
        FormHelperTextProps={{ className: 'url-error' }}
      />
      <StyledTextField
        label="Token endpoint url"
        onChange={onAppFieldChange}
        name="tokenEndpoint"
        error={invalidTokenEndpointUrl}
        value={appToOverride.tokenEndpoint}
        helperText={
          invalidTokenEndpointUrl ? 'Please provide a valid token endpoint URL' : undefined
        }
        FormHelperTextProps={{ className: 'url-error' }}
      />
      <StyledTextField
        label="URL iframe url"
        onChange={onAppFieldChange}
        name="url"
        error={invalidUrl}
        value={appToOverride.url}
        helperText={
          <span>
            <b>Deprecated:</b> Use the allowed iframe urls fiend on the app manifest instead.{' '}
            {invalidUrl ? ' Please provide a valid URL' : undefined}
          </span>
        }
        FormHelperTextProps={{ className: 'url-error' }}
      />
      <div className="align-right">
        <Button
          buttonType="small"
          disabled={
            invalidLaunchEndpointUrl ||
            invalidTokenEndpointUrl ||
            invalidUrl ||
            !appToOverride.appId ||
            (!appToOverride.version &&
              !appToOverride.url &&
              !appToOverride.launchEndpoint &&
              !appToOverride.tokenEndpoint)
          }
          onClick={addApp}
        >
          {isNewApp ? 'Add' : 'Update'}
        </Button>
      </div>
    </>
  );
};

export default EditApplicationOverride;
